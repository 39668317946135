/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LightYellowChatbiz, LightBlueChatbiz } from '../../_Variable/var';

const Wrapper = styled.button`
	&.btnPrimaryDefault {
		border: none;
		outline: none;
		background: #ff8f1c;
		box-shadow: 0px 5px 12px rgba(21, 88, 121, 0.15);
		border-radius: 6px;
		padding: 0.938rem 3.188rem !important;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 0.813rem;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		&:disabled,
		&[disabled] {
			background: #bdbdbd;
			div {
				color: #ffffff;
			}
		}

		&:hover {
			background-color: ${LightYellowChatbiz};
			border-color: ${LightYellowChatbiz};
			color: #4f4f4f;
			div {
				color: #4f4f4f;
			}
		}
		&:active {
			background-color: ${LightBlueChatbiz};
			border-color: ${LightBlueChatbiz};
			text-decoration: none;
			color: #ffffff;
			div {
				color: #ffffff;
			}
		}
		div {
			color: #ffffff;
			font-style: normal;
			font-weight: bold;
		}
	}
`;

const WrapperLink = styled.a`
	&.btnPrimaryDefault {
		border: none;
		outline: none;
		background: #ff8f1c;
		box-shadow: 0px 5px 12px rgba(21, 88, 121, 0.15);
		border-radius: 6px;
		padding: 0.938rem 3.188rem !important;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 0.813rem;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		&:disabled,
		&[disabled] {
			background: #bdbdbd;
			div {
				color: #ffffff;
			}
		}

		&:hover {
			background-color: ${LightYellowChatbiz};
			border-color: ${LightYellowChatbiz};
			text-decoration: none;
			color: #4f4f4f;
			div {
				color: #4f4f4f;
			}
		}
		&:active {
			background-color: ${LightBlueChatbiz};
			border-color: ${LightBlueChatbiz};
			text-decoration: none;
			color: #ffffff;
			div {
				color: #ffffff;
			}
		}
		div {
			color: #ffffff;
			font-style: normal;
			font-weight: bold;
		}
	}
`;

const ButtonPrimary = ({ children, className, type, onClick, isDisable, id }) => {
	return (
		<Wrapper
			className={className || 'btnPrimaryDefault'}
			type={type}
			onClick={onClick}
			disabled={isDisable}
			{...(id && { id })}
		>
			{children}
		</Wrapper>
	);
};

export const ButtonPrimaryLink = ({ children, className, type, href, target, isDisable, id }) => {
	return (
		<WrapperLink
			className={className || 'btnPrimaryDefault'}
			type={type}
			href={href}
			target={target}
			disabled={isDisable}
			{...(id && { id })}
		>
			{children}
		</WrapperLink>
	);
};

ButtonPrimary.defaultProps = {
	className: '',
	type: 'button',
	isDisable: false,
	id: '',
};

ButtonPrimary.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	isDisable: PropTypes.bool,
	id: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ButtonPrimaryLink.defaultProps = {
	className: '',
	type: 'button',
	isDisable: false,
	id: '',
};

ButtonPrimaryLink.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	href: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	isDisable: PropTypes.bool,
	id: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ButtonPrimary;
