/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DefaultVariable from '../atoms/_Variable/_hoc';
import '../_style/globalStyle.scss';

const withDefautlHoc = WrappedComponent => {
	const HOC = props => {
		return (
			<DefaultVariable>
				<WrappedComponent {...props} />
			</DefaultVariable>
		);
	};

	return HOC;
};

export default withDefautlHoc;
