/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BlueChatbiz } from '../../_Variable/var';

const Wrapper = styled.button`
	&.btnLinkDefault {
		outline: none;
		background-color: transparent;
		border: 1px solid ${BlueChatbiz};
		box-sizing: border-box;
		border-radius: 8px;
		padding: 0.625rem 1.25rem;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${BlueChatbiz};
		div {
			color: ${BlueChatbiz};
		}
		&.btnWhite {
			border: 2px solid #ffffff;

			div {
				color: #ffffff;
			}
		}

		&:hover {
			border-color: #0664be !important;
			background: #f3f5f6;
			color: #0664be; !important;
			text-decoration: none;
			div {
				color: #0664be;!important;
			}
		}
		&:active {
			border-color: #4f4f4f !important;

			div {
				color: #4f4f4f;
			}
		}

		&.bolder {
			border: 2px solid ${BlueChatbiz};
			font-style: normal;
			font-weight: bold;
		}
	}
`;

const WrapperLink = styled.a`
	&.btnLinkDefault {
		outline: none;
		background-color: transparent;
		border: 1px solid ${BlueChatbiz};
		box-sizing: border-box;
		border-radius: 8px;
		padding: 0.625rem 1.25rem;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${BlueChatbiz};
		div {
			color: ${BlueChatbiz};
		}
		&.btnWhite {
			border: 2px solid #ffffff;

			div {
				color: #ffffff;
			}
		}

		&:hover {
			border-color: #0664be !important;
			background: #f3f5f6;
			color: #0664be; !important;
			text-decoration: none;
			div {
				color: #0664be; !important;
			}
		}
		&:active {
			border-color: #4f4f4f !important;
			div {
				color: #4f4f4f;
			}
		}

		&.bolder {
			border: 2px solid ${BlueChatbiz};
			font-style: normal;
			font-weight: bold;
		}
	}
`;

const ButtonLink = ({ children, className, onClick, id, type, isDisable }) => {
	return (
		<Wrapper
			className={className || 'btnLinkDefault'}
			onClick={onClick}
			{...(id && { id })}
			type={type}
			disabled={isDisable}
		>
			{children}
		</Wrapper>
	);
};

export const ButtonLinkHref = ({ children, className, href, target, id }) => {
	return (
		<WrapperLink
			className={className || 'btnLinkDefault'}
			href={href}
			target={target}
			{...(id && { id })}
		>
			{children}
		</WrapperLink>
	);
};

ButtonLink.defaultProps = {
	className: '',
	id: '',
	type: 'button',
	isDisable: false,
};

ButtonLink.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	id: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	isDisable: PropTypes.bool,
};

ButtonLinkHref.defaultProps = {
	className: '',
	id: '',
};

ButtonLinkHref.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	id: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ButtonLink;
