export const BlueChatbiz = '#066FC2';
export const LightBlueChatbiz = '#009DDB';
export const GrayBlueChatbiz = '#BBDDE6';
export const GrayWhiteChatbiz = '#F9FBFF';
export const LightYellowChatbiz = '#F9E27D';
export const OrangeChatbiz = '#FF8F1C';
export const ExtraMiniMobile = '320px';
export const MiniMobile = '375px';
export const Mobile = '480px';
export const Tablet = '767px';
export const Netbook = '991px';
export const Notebook = '1280px';
export const AverageSize = '1366px';
export const Large = '1690px';
export const ExtraLarge = '1920px';

export default {
	BlueChatbiz,
	LightBlueChatbiz,
	GrayBlueChatbiz,
	GrayWhiteChatbiz,
	LightYellowChatbiz,
	OrangeChatbiz,
	ExtraMiniMobile,
	MiniMobile,
	Mobile,
	Tablet,
	Netbook,
	Notebook,
	AverageSize,
	Large,
	ExtraLarge,
};
