import React, { useState, useEffect } from 'react';
import { changeLocale } from 'gatsby-plugin-intl';
import queryString from 'query-string';
import apiLocation from '../../api/location';

const withIntl = WrappedComponent => {
	const HOC = () => {
		const [isLoadingLang, setIsLoadingLang] = useState(true);

		const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '';
		const splitCurrentLocation = currentLocation.split('/');

		const conditionEnPrefix = () => {
			if (splitCurrentLocation.includes('en')) return true;

			return false;
		};

		const [enPrefix, setEnPrefix] = useState(conditionEnPrefix());

		useEffect(() => {
			const fetchData = async () => {
				const parsedQueryString = queryString.parse(window.location.search);

				const cancleDetection = parsedQueryString.lang === 'id';
				if (
					!splitCurrentLocation.includes('id') &&
					!splitCurrentLocation.includes('en') &&
					!cancleDetection
				) {
					try {
						const { data } = await apiLocation();
						if (!data.languages.includes('id')) {
							setEnPrefix(false);
							changeLocale('en');
						}
						setIsLoadingLang(false);
					} catch (e) {
						setIsLoadingLang(false);
					}
				} else {
					setIsLoadingLang(false);
				}
			};
			fetchData();
		}, []);

		// if (isLoadingLang) return <></>;

		return <WrappedComponent isLoadingLang={isLoadingLang} enPrefix={enPrefix} />;
	};

	return HOC;
};

export default withIntl;
