import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as data from '../var';

const documentInject = (key, value) => {
	document.documentElement.style.setProperty(key, value || '#fff');
};

const SetColor = ({ children }) => {
	useEffect(() => {
		const flag = '--cb-';

		documentInject(`${flag}BlueChatbiz`, data.BlueChatbiz);
		documentInject(`${flag}LightBlueChatbiz`, data.LightBlueChatbiz);
		documentInject(`${flag}GrayBlueChatbiz`, data.GrayBlueChatbiz);
		documentInject(`${flag}GrayWhiteChatbiz`, data.GrayWhiteChatbiz);
		documentInject(`${flag}LightYellowChatbiz`, data.LightYellowChatbiz);
		documentInject(`${flag}OrangeChatbiz`, data.OrangeChatbiz);

		documentInject(`${flag}OrangeChatbiz`, data.OrangeChatbiz);
		documentInject(`${flag}MiniMobile`, data.MiniMobile);
		documentInject(`${flag}Mobile`, data.Mobile);
		documentInject(`${flag}Tablet`, data.Tablet);
		documentInject(`${flag}Netbook`, data.Netbook);
		documentInject(`${flag}Notebook`, data.Notebook);
		documentInject(`${flag}Large`, data.Large);
	}, []);

	return <>{children}</>;
};

SetColor.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SetColor;
