import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Large } from '../../_Variable/var';

const Wrapper = styled.div`
	&.pDefault {
		font-family: Nunito, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 150%;
		color: #4f4f4f;
		@media only screen and (min-width: ${Large}) {
			font-size: 1.25rem;
		}
	}
`;

const Paragraph = ({ children, className }) => {
	return <Wrapper className={className || 'pDefault'}>{children}</Wrapper>;
};

Paragraph.defaultProps = {
	className: '',
};

Paragraph.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Paragraph;
